import emailIcon from '../assets/email_icon.png';
import githubIcon from '../assets/github_icon.png';
import linkedinIcon from '../assets/linkedin_icon.png';
import twitterIcon from '../assets/twitter_icon.png';
 
const SOCIAL_PROFILES = [
   {
       id: 1,
       link: 'mailto:owen.shuai.liu@berkeley.edu',
       image: emailIcon
   },
   {
       id: 2,
       link: 'https://github.com/OwenShuaiLiu',
       image: githubIcon
   },
   {
       id: 3,
       link: 'https://www.linkedin.com/in/owen-shuai-liu',
       image: linkedinIcon
   },
   {
       id: 4,
       link: 'https://twitter.com/ShuaiOwen',
       image: twitterIcon
   },
];
 
export default SOCIAL_PROFILES;
